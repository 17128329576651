import { setCookie, destroyCookie, parseCookies } from 'nookies'
import nookies from 'nookies'
import { NextPageContext } from 'next'
import { CookieSerializeOptions } from 'next/dist/server/web/types'
export default class StorageService {
  setItem(
    key: string,
    value: string,
    options?: CookieSerializeOptions,
    ctx?: NextPageContext
  ) {
    ctx
      ? nookies.set(ctx, key, value, options)
      : setCookie(null, key, value, options)
  }

  getItem(key: string, ctx?: NextPageContext): string {
    try {
      let cookies = parseCookies(null)

      ctx ? (cookies = nookies.get(ctx)) : (cookies = parseCookies(null))

      const item = cookies[key]

      return JSON.parse(JSON.stringify(item))
    } catch (err) {
      return ''
    }
  }

  removeItem(key: string, ctx?: NextPageContext) {
    ctx ? nookies.destroy(ctx, key) : destroyCookie(null, key)
  }
}
