import { CookieSerializeOptions } from 'next/dist/server/web/types'
import ApiService from './ApiService'
import StorageService from './StorageService'

export default class AuthenticationApiService extends ApiService {
  constructor() {
    super('/api/auth-token')
    this.storageService = new StorageService()
  }

  async login(
    loginDTO: { username: string; password: string },
    stayLogged: boolean
  ) {
    try {
      let options: CookieSerializeOptions = {}
      const response = await this.post('/', loginDTO)
      if (!stayLogged) options = { maxAge: 21600, path: '/' }

      this.storageService.setItem('TOKEN', response.data.token, options)
      this.registerToken(response.data.token)

      return response.data.token
    } catch (error) {
      return error
    }
  }

  logout() {
    this.storageService.removeItem('TOKEN')
  }

  getToken(): string {
    return this.storageService.getItem('TOKEN')
  }

  async isAuthenticated() {
    // const user = this.getLoggedUser();
    // const token = this.getToken();

    // if(!user || !token){
    //     return false;
    // }

    // const tokenDTO = {
    //     "token": token
    // };

    // const response = await this.isTokenValid(tokenDTO);
    // return response.data;

    return true
  }
}
