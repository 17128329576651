import axios from 'axios'
import { NextPageContext } from 'next'

import StorageService from './StorageService'

export const LOGGED_USER = 'loggedUser'
export const TOKEN = 'token'

export const httpClient = axios.create({
  baseURL: process.env.NEXT_PUBLIC_BASE_URL,
})

export default class ApiService {
  endpoint: string
  storageService: StorageService

  constructor(endpoint: string, ctx?: NextPageContext) {
    this.endpoint = endpoint
    this.storageService = new StorageService()

    const hasToken = this.storageService.getItem('TOKEN', ctx)

    if (hasToken) this.registerToken(hasToken)
  }

  registerToken(token: string) {
    if (token)
      httpClient.defaults.headers.common['Authorization'] = `Token ${token}`
  }

  setHeader(name: string, value: string) {
    httpClient.defaults.headers.common[name] = `${value}`
  }

  post(url: string, params: any, options?: any) {
    url = this.buildUrl(url)
    return httpClient.post(url, params, options)
  }

  put(url: string, params: any) {
    url = this.buildUrl(url)
    return httpClient.put(url, params)
  }

  pacth(url: string, params: any, options?: any) {
    url = this.buildUrl(url)
    return httpClient.patch(url, params, options)
  }

  delete(url: string) {
    url = this.buildUrl(url)
    return httpClient.delete(url)
  }

  get(url: string) {
    url = this.buildUrl(url)
    return httpClient.get(url)
  }

  buildUrl(url: string) {
    return `${this.endpoint}${url}`
  }
}
