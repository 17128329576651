import Link from 'next/link'
import { useState } from 'react'
import styled from 'styled-components'

export default function Navbar() {
  const [isOpenMenu, setIsOpenMenu] = useState(false)

  return (
    <Nav openNav={isOpenMenu}>
      <Menu onClick={() => setIsOpenMenu(true)} />
      <div className="content">
        <Link href={'/ava'}>
          <SectionOptionNav>AVA</SectionOptionNav>
        </Link>
        <Link href={'/nucleo-de-inovacao'}>
          <SectionOptionNav>Núcleo de inovação</SectionOptionNav>
        </Link>
        {/* <Link href={'/observatorio'}>
          <SectionOptionNav>Observatório</SectionOptionNav>
        </Link> */}
        <Link href="/rede-repositorio">
          <SectionOptionNav>Repositório</SectionOptionNav>
        </Link>
      </div>

      <div className="background" onClick={() => setIsOpenMenu(false)} />
    </Nav>
  )
}

const Nav = styled.nav<{ openNav: boolean }>`
  display: flex;
  align-items: center;

  height: 100%;
  .content {
    display: flex;
    align-items: center;
    column-gap: 48px;
    height: 100%;
  }
  .background {
    display: none;
  }

  @media (max-width: 1340px) {
    .content {
      column-gap: 16px;
    }
  }

  @media (max-width: 920px) {
    position: absolute;
    z-index: 11;
    left: 24px;
    .content {
      width: ${({ openNav }) => (openNav ? '264px' : '0px')};
      height: 100vh;
      transition: 0.2s;
      flex-direction: column;
      align-items: start;
      position: absolute;
      padding-top: 48px;
      left: -24px;
      top: 0;
      z-index: 12;
      background: #5290ff;
      overflow: hidden;
    }

    .background {
      display: flex;
      position: absolute;
      left: -24px;
      top: 0;
      width: ${({ openNav }) => (openNav ? '100vw' : '0vw')};
      background: rgb(0, 0, 0, 0.5);
      height: 100vh;
    }
  }
`

const Menu = styled.button`
  display: none;
  background: transparent;
  border: none;
  @media (max-width: 920px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 10;
    ::before {
      content: '';
      width: 21px;
      height: 2px;
      border-top: 2px solid #5290ff;
      border-bottom: 1px solid #5290ff;
      padding: 2px 0;
    }
    ::after {
      content: '';
      width: 21px;
      height: 2px;
      border-top: 1px solid #5290ff;
      border-bottom: 2px solid #5290ff;
      padding: 2px 0;
    }
  }
`

const SectionOptionNav = styled.section`
  position: relative;
  font-family: 'Epilogue';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  display: flex;
  align-items: center;
  line-height: 24px;
  height: 100%;
  text-align: center;
  cursor: pointer;
  justify-content: center;
  color: #ffffff;
  span {
    max-width: 15px;
    word-wrap: break-word;
  }
  ::before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 0%;
    border-bottom: 2px solid #f3cb20;
    transition: 0.2s;
  }

  :hover {
    ::before {
      width: 100%;
      transition: 0.2s;
    }

    .dropdown-nav-container {
      max-width: 1000px;
      padding: 0 20px 0 50px;
      opacity: 1;
      transition: 0.4s;
    }
  }

  @media (max-width: 920px) {
    height: 50px;
    width: 100%;
    justify-content: start;
    border-bottom: 1px solid #4b88fb;
    padding-left: 24px;
    flex-wrap: nowrap !important;
    text-align: left;
  }
`
