/* eslint-disable @typescript-eslint/no-empty-function */
import { useRouter } from 'next/router'
import React, { ReactNode, useContext, useMemo, useState } from 'react'
import AuthenticationApiService from '../services/AuthenticationApiService'
import UsersApiService from '../services/UsersApiService'

interface UserInterface {
  id: number
  username: string
  name: string
  url: string
  isLogged: boolean
}

const userDefault: UserInterface = {
  id: 0,
  username: '',
  name: '',
  url: '',
  isLogged: false,
}

interface AuthenticationContextInterface {
  user: UserInterface
  login: (
    value: { username: string; password: string },
    rememberMe: boolean
  ) => void
  logout: () => void
}

const AuthenticationContext =
  React.createContext<AuthenticationContextInterface>({
    user: userDefault,
    login: () => {},
    logout: () => {},
  })

export const useAuthenticationContext = () => useContext(AuthenticationContext)

interface AuthenticationContextProviderProps {
  children: ReactNode
}

export default function AuthenticationContextProvider({
  children,
}: AuthenticationContextProviderProps) {
  const authentication = new AuthenticationApiService()
  const userService = new UsersApiService()
  const router = useRouter()

  const [user, setUser] = useState<UserInterface>(userDefault)

  useMemo(() => {
    const haveToken = authentication.getToken()
    if (haveToken) getUserLogged()
  }, [])

  async function login(
    value: { username: string; password: string },
    stayLogged: boolean
  ) {
    await authentication.login(value, stayLogged)
    const res = await userService.findUserLogged()
    if (res) {
      setUser({
        ...res.data,
        isLogged: true,
      })

      router.push('/')
    }
  }

  async function getUserLogged() {
    const res = await userService.findUserLogged()
    let userLogged = userDefault

    if (res)
      userLogged = {
        ...res.data,
        isLogged: true,
      }

    setUser(userLogged)
  }

  function logout() {
    setUser(userDefault)
    authentication.logout()
  }

  return (
    <AuthenticationContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthenticationContext.Provider>
  )
}
