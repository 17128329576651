// Components

import Image from 'next/image'

// Images
import Logo from '../../../../public/assets/icons/Logo.png'

// Style
import styled from 'styled-components'

export default function FooterContainer() {
  return (
    <Footer>
      <SocialFooterContainer>
        <ImageContainer>
          <Image src={Logo} width={240} height={81} />
        </ImageContainer>

        <FooterSpan>
          Uma iniciativa do Núcleo de Excelência em Tecnologias Sociais (NEES)
          da Universidade Federal de Alagoas (UFAL) em parceria com o Ministério
          da Educação (MEC)
        </FooterSpan>

        {/* <SocialButtonsContainer>
          <SocialButton icon={InstagramIcon} tooltip="Instagram" />
          <SocialButton icon={FacebookIcon} tooltip="Facebook" />
          <SocialButton icon={TwitterIcon} tooltip="Twitter" />
          <SocialButton icon={YouTubeIcon} tooltip="Youtube" />
        </SocialButtonsContainer> */}
      </SocialFooterContainer>

      {/* <NewsletterContainer>
        <NewsletterTitle>Cadastre-se</NewsletterTitle>
        <FooterSpan>
          Quer ficar por dentro de tudo o que acontece na nossa Rede?
          <br />
          <br />
          Basta se cadastrar no formulário abaixo para receber nossas notícias e
          acompanhar tudo o que realizamos para impactar positivamente o
          ecossistema educacional brasileiro
        </FooterSpan>
        <NewsletterInput />
      </NewsletterContainer> */}

      <LanguageSwitchContainer>
        <WhitePartFooter />
        <BlackPartFooter>
          <BlackPartFooterTitle>
            NEES - All rights reserved
          </BlackPartFooterTitle>
          {/* <BlackPartFooterLanguageContainer>
            <Image width={20} height={20} src={GreyGlobeIcon} />
            <LanguageSpan>PT</LanguageSpan>
            <LanguageSpan>EN</LanguageSpan>
          </BlackPartFooterLanguageContainer> */}
        </BlackPartFooter>
      </LanguageSwitchContainer>
    </Footer>
  )
}

const SocialFooterContainer = styled.div`
  height: fit-content;
  padding-left: 7.5rem;
  padding-right: 5rem;
  padding-top: 1rem;
  padding-bottom: 1.07rem;

  @media (max-width: 1000px) {
    padding-left: 3.5rem;
    padding-right: 2rem;
  }

  @media (max-width: 650px) {
    padding: 0.5rem 24px;
  }
`

const Footer = styled.footer`
  display: grid;

  background: #fff;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr 0fr;
  row-gap: 1px;
  column-gap: 1px;

  @media (max-width: 650px) {
    display: block;
  }
`

const NewsletterContainer = styled.div`
  padding-left: 5.3125rem;
  padding-top: 1rem;
  padding-bottom: 2.5rem;
  padding-right: 6rem;
  @media (max-width: 1000px) {
    padding-left: 2rem;
    padding-right: 3.5rem;
  }
  @media (max-width: 650px) {
    padding: 0 24px;
  }
`

const NewsletterTitle = styled.h1`
  font-family: Epilogue, sans-serif;
  font-weight: 600;
  font-size: 1.125rem;
  margin-bottom: 0.75rem;
  margin-top: 2.5625rem;
`

const ImageContainer = styled.div`
  width: fit-content;
  height: fit-content;
  margin-top: 2.5625rem;
  margin-bottom: 2.53rem;
`

const SocialButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1.25rem;
  margin-top: 2.875rem;
`

const LanguageSwitchContainer = styled.div`
  display: flex;
  flex-direction: column;

  grid-column-start: -3;
  grid-column-end: 3;
`

const WhitePartFooter = styled.div`
  height: 3.125rem;
  width: 100%;
`

const BlackPartFooter = styled.div`
  height: 2.75rem;
  width: 100%;
  background-color: #151515;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const BlackPartFooterTitle = styled.span`
  color: #fff;
  font-family: Epilogue, sans-serif;
  margin-left: 7.5rem;
`

const BlackPartFooterLanguageContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-right: 7.5rem;
`

const FooterSpan = styled.span`
  color: rgba(0, 0, 0, 0.6);
  font-family: Epilogue, sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
`

const LanguageSpan = styled.span`
  font-family: Epilogue, sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  color: #fff;

  &:hover {
    cursor: pointer;
  }
`
