import Image from 'next/image'
import styled from 'styled-components'
import Navbar from '../Navbar'
import Logo from '/public/assets/icons/Logo.png'
import Link from 'next/link'
import { useAuthenticationContext } from '../../../contexts/AuthenticationContext'

export default function Header() {
  const { user } = useAuthenticationContext()

  return (
    <FakeSpace>
      <HeaderContainer>
        {/* <SectionHeaderSearch>
        <InputSearch />
        <LanguageContainer>
          <ContactLink>Contato</ContactLink>
          <Image src={GlobalIcon} alt="Icone Linguagem" />
          <TextLanguage select={true}>PT</TextLanguage>
        </LanguageContainer>
      </SectionHeaderSearch> */}
        <SectionHeader>
          <DivSectionHeaderLeft>
            <Link href={'/'}>
              <H1Logo>
                <Image
                  src={Logo}
                  alt="Logo Rede Brasileira de Ensino Híbrido"
                  objectFit="contain"
                />
              </H1Logo>
            </Link>
          </DivSectionHeaderLeft>
          <DivSectionHeaderRight>
            <Navbar />
          </DivSectionHeaderRight>
        </SectionHeader>
      </HeaderContainer>
    </FakeSpace>
  )
}

const FakeSpace = styled.div`
  width: 100%;
  height: 82px;
  @media (max-width: 920px) {
    height: 60px;
  }
`

const HeaderContainer = styled.header`
  background: ${({ theme }) => theme.colors.white};
  width: 100%;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #f4f5f8;
  position: fixed;
  z-index: 22;
`

const SectionHeader = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 82px;
  @media (max-width: 920px) {
    height: 60px;
  }
`

const SectionHeaderSearch = styled(SectionHeader)`
  height: 56px;
  border-bottom: 1px solid #ebf2ff;
  @media (max-width: 920px) {
    padding: 6px 0;
  }
  @media (max-width: 620px) {
    height: 48px;
  }
`

const H1Logo = styled.h1`
  width: 226.71px;
  display: flex;
  align-items: center;
  height: 48px;
  cursor: pointer;
  @media (max-width: 920px) {
    width: 146px;
    height: max-content;
  }
  @media (max-width: 620px) {
    width: calc(100% - 16px);
    max-width: 146px;

    height: max-content;
  }
`

const DivSectionHeaderLeft = styled.div`
  width: 29.65%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0px 0px 0px 120px;
  @media (max-width: 1440px) {
    padding: 0px 0px 0px 40px;
  }
  @media (max-width: 920px) {
    padding: 0 0 0 72px;
    width: 70%;
  }
`

const DivSectionHeaderRight = styled(DivSectionHeaderLeft)`
  background: #5290ff;
  padding: 0px 120px 0px 48px;
  justify-content: space-between;
  width: 70.35%;
  @media (max-width: 1440px) {
    padding: 0px 40px 0px 48px;
  }
  @media (max-width: 920px) {
    background: #fff;
    justify-content: flex-end;

    width: 30%;
    padding: 0px 24px 0 0;
  }
`

const LanguageContainer = styled.section`
  position: absolute;
  right: 120px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 1280px) {
    right: 40px;
  }
  @media (max-width: 950px) {
    display: none;
  }
`

const ContactLink = styled.a`
  font-family: 'Epilogue';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 14px;
  letter-spacing: -0.02em;
  text-decoration: none;
  margin-top: 2px;
  color: #5290ff;
  margin-right: 20px;
  cursor: pointer;
`
const TextLanguage = styled.p<{ select: boolean }>`
  font-family: 'Epilogue';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  margin-left: 14px;
  line-height: 24px;
  color: ${({ select }) => (select ? '#5290FF' : '#DFDFDF')};
`

const ButtonNotifications = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
`
const DivIsLogged = styled.div`
  display: flex;
  align-items: center;
`

const LinkLogin = styled.a`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  column-gap: 8px;
  min-width: 72px;
  background: none;
  border: none;
  padding: 0;
  font-family: 'Inter';
  text-decoration: none;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  cursor: pointer;
  font-feature-settings: 'pnum' on, 'lnum' on;
  color: ${({ theme }) => theme.colors.white};
  margin-left: 16px;

  .mobile-user-icon {
    display: none;
    position: relative;
  }

  ::before {
    content: 'Entrar';
    margin-top: -4px;
  }

  @media (max-width: 920px) {
    padding: 8px 0 8px 8px;
    border-radius: 4px;

    .desktop-user-icon {
      display: none;
    }
    .mobile-user-icon {
      display: unset;
      width: 21px;
      height: 21px;
    }
  }
  @media (max-width: 620px) {
    font-size: 14px;
    column-gap: 0px;
    line-height: 18px;

    ::before {
      content: '';
    }
  }
`
