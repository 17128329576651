import { ReactNode } from 'react'
import styled from 'styled-components'
import FooterContainer from '../../components/organisms/FooterContainer'
import Header from '../../components/organisms/Header'

interface FullscreenTemplateProps {
  children: ReactNode
}

export default function FullscreenTemplate({
  children,
}: FullscreenTemplateProps) {
  return (
    <ContainerFullscreenTemplate>
      <Header />
      {children}

      <FooterContainer />
    </ContainerFullscreenTemplate>
  )
}

const ContainerFullscreenTemplate = styled.main`
  width: 100vw;
  min-height: 100vh;
  background: #f6f7f9;
  overflow-x: hidden;
`
