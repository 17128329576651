import ApiService from './ApiService'

export default class UsersApiService extends ApiService {
  constructor() {
    super('/api/users')
  }

  findAllUsers() {
    return this.get('/')
  }

  findUserLogged() {
    try {
      return this.get('/me/')
    } catch (_) {
      return null
    }
  }
}
